import { DropDownItem } from "@core-models/generic.model";

export namespace ServiceConst
{
  export const OxAddOn: number[] = [EAddonType.StorageOX, EAddonType.ModuleSecurity, EAddonType.ModuleProductivity];

  export const CapacityEmailOXOptionsGB: DropDownItem[] = [
    { value: 1, label: "1 GB" },
    { value: 5, label: "5 GB" },
    { value: 10, label: "10 GB" },
    { value: 20, label: "20 GB" },
    { value: 50, label: "50 GB" },
    { value: -1, label: "Personalizar" }
  ];

  export const AdditionalServicePkg = new Map<EAdditionalService, string>([
    [EAdditionalService.DomainAdvancedSecurity, 'Seguridad Avanzada de dominio'],
    [EAdditionalService.InternetPresence, 'Presencia en Internet'],
    [EAdditionalService.SMSAlerts, 'Alertas SMS'],
    [EAdditionalService.CustomDNS, 'DNS Personalizado'],
    [EAdditionalService.WebsiteBuilder_PVSite, 'Creador de sitios Web (Sitio PV)'],
    [EAdditionalService.EmailRedirect, 'Redireccionamiento correo'],
    [EAdditionalService.WebsiteRedirect, 'Redireccionamiento web'],
    [EAdditionalService.FreeWebsite, 'Sitio Web Gratuito'],
    [EAdditionalService.PremiumEmail, 'Email Premium'],
    [EAdditionalService.OnlineStore, 'Tienda en Linea (ePages)'],
    [EAdditionalService.WebsiteBuilderEPages, 'Creador de Sitios Web'],
    [EAdditionalService.SSLCertificate, 'Certificado SSL'],
    [EAdditionalService.Hosting, 'Hosting'],
    [EAdditionalService.WebsiteBuilderWordPress, 'Creador de Sitios (WordPress'],
    [EAdditionalService.Microsoft, 'Microsoft'],
    [EAdditionalService.EmailOpenxchange, 'Email Openxchange']
  ]);


  export const AdditionalServicePlans = new Map<number, string>([
    [1, 'Email Plus'],
    [2, 'Email Premium'],
    [3, 'Tienda en linea - Inicia'],
    [4, 'Tienda en linea - Mejora'],
    [5, 'Tienda en linea - Crece'],
    [6, 'Tienda en linea - Sin Limites'],
    [7, 'Sitio Web - Basico Contigo'],
    [8, 'Sitio Web - Plus Contigo'],
    [9, 'Tienda en linea - Plus Contigo'],
    [10, 'Sin Plan'],
    [11, 'PositiveSSL DV'],
    [12, 'PositiveSSL Multidominio DV'],
    [13, 'PositiveSSL Multidominio EV'],
    [14, 'PositiveSSL Wildcard EV'],
    [15, 'SSL GeoTrust True BusinessID OV'],
    [16, 'PositiveSSL EV'],
    [17, 'SSL GeoTrust True BusinessID Wildcard OV'],
    [18, 'SSL GeoTrust True BusinessID Multidominio OV'],
    [19, 'Hosting - Basico'],
    [20, 'Hosting - Plus'],
    [21, 'Hosting - Premium'],
    [22, 'Sitio Web - Basico por ti'],
    [23, 'Sitio Web - Plus por ti'],
    [24, 'Tienda en Linea - Plus por ti'],
    [25, 'Email - Basico'],
    [26, 'Tienda en Linea - Basico Contigo'],
    [27, 'Tienda en Linea - Basico por ti'],
    [28, 'Branding'],
    [29, 'Kit Redes Basico'],
    [30, 'Guia Ads en Buscadores'],
    [31, 'Suite Digital para tu Negocio'],
    [32, 'Paquete Post Adicionales'],
    [33, 'Paquete Historias Destacadas'],
    [34, 'Servicio Diseño de Logo'],
    [35, 'Sitio WordPress - Basico'],
    [36, 'Sitio WordPress - Plus'],
    [37, 'Tienda WordPress - Basico'],
    [38, 'Tienda WordPress - Plus'],
    [39, 'RapidSSL DV'],
    [40, 'RapidSSL WildCard DV'],
    [41, 'Thawte Multidominio DV'],
    [42, 'Thawte Multidominio EV'],
    [43, 'Thawte EV'],
    [44, 'Office 365 E3'],
    [45, 'Microsoft 365 E3'],
    [46, 'Microsoft 365 F3'],
    [47, 'Office 365 E3 Trial'],
    [48, 'Office LTSC Professional Plus 2021'],
    [49, 'Project Plan 1'],
    [50, 'Power BI Pro'],
    [51, 'E-mail 5 GB'],
    [52, 'E-mail 10 GB'],
    [53, 'E-mail 50 GB'],
    [54, 'Sitio Web - Hazlo tu mismo'],
    [55, 'Microsoft 365 Empresa Basico'],
    [56, 'Microsoft 365 Empresa Estandar']
  ]);

  export const MaxQuantity: number = 100;
}

export const enum EServiceType
{
  Domain = 0,
  Service,
  DiverseService,
  Addon
}

export const enum EServiceName
{
  Domain = "domain",
  Service = "service",
  DiverseService = "diverseService",
  Addon = "addOn"
}

export const enum EAdditionalService
{
  DomainAdvancedSecurity = 1,
  InternetPresence,
  SMSAlerts,
  CustomDNS,
  WebsiteBuilder_PVSite,
  EmailRedirect,
  WebsiteRedirect,
  FreeWebsite,
  PremiumEmail,
  OnlineStore,
  WebsiteBuilderEPages,
  SSLCertificate,
  Hosting,
  DigitalMarketing,
  WebsiteBuilderWordPress,
  Microsoft,
  EmailOpenxchange,
  Duda
}

export const enum EAddonType
{
  StorageOX = 1,
  ModuleSecurity = 2,
  ModuleProductivity = 3
}

export const enum EServiceCode
{
  ASP = 'ASP',
  APL = 'APL',
  DOT = 'DOT'
}

export const enum EServiceCode
{
  // Verify Directus "clave_interna" matches
  Hosting = "hosting-servicio",

  EmailPremium = "email_perzonalizado",
  EmailOX = "email",

  SSL = "ssl-servicio",

  WebSite = "creador-sitios",
  WebSitePT = "creador-sitios-porti",
  WebSiteC = "creador-sitios-contigo",

  OnlineStore = "tienda_linea",
  OnlineStorePT = "tienda-linea-porti",
  OnlineStoreC = "tienda-linea-contigo",

  WordPressStore = "wp-stores",
  WordPressSites = "wp-sites",

  CertificateSSL = "normal-ssl",
  WildcardSSL = "wildcard-ssl",
  MultidomainSSL = "multidomain-ssl",

  DigitalMKT = "digial-mtk",
  MKTBranding = "digial-mtk-branding",
  MKTSearchEngineAds = "digial-mtk-ads",
  MKTSocialMedia = "digial-mtk-social-media",

  Microsoft = "microsoft",
  Office = "office",
  MicrosoftOthers = "mcft-others",

  DudaService = "duda-service"
}

export const enum EClassIcon
{
  BrowserHand = "i-browser-hand",
  CalendarDate = "i-calendar-date",
  EmailActionWarning = "i-email-action-warning",
  HeadphonesCustomerSupportHuman = "i-headphones-customer-support-human",
  MoodHappyLaptop = "i-mood-happy-laptop"
}

export const enum EAdditionalServicePlan
{
  EmailOX5G = 51,
  WixDoItYourself = 54
}
