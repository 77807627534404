import { isPlatformBrowser } from "@angular/common";
import { Component, DestroyRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EServiceCode } from "@core-constants/product-service.const";
import { PurchaseSuggestionDataService } from '@core-data-services/purchase-suggestions.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { CheckoutManager } from "@core-managers/checkout.manager";
import { PurchaseSuggestionManager } from "@core-managers/purchasse-suggestion.manager";
import { SettingsManager } from "@core-managers/settings.manager";
import { TokenManager } from "@core-managers/token.manager";
import { AdditionalServiceToCart } from "@core-models/aditional-service.model";
import { IDomainCoverage } from "@core-models/domain.model";
import { IAdditionalPurchaseSuggestionSelected, IShoppingCartGeneralStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { ShoppingCartItem } from "@core-models/shopping-cart-items.model";
import { BroadcastService } from '@shared-services/broadcast.service';
import { ToastService } from '@shared-services/toast.service';
import { filter, lastValueFrom } from 'rxjs';
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { IProductAddGtm } from "@core-models/product.model";
import { EventLocationSite } from "@core-constants/gtm-const";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PixelService } from "@core-data-services/pixel.data-service";
import { PixelAddToCartDataModel } from "@core-models/pixel.model";

@Component({
  selector: 'app-additional-purchase-suggestion',
  templateUrl: './additional-purchase-suggestion.component.html',
  styleUrls: ['./additional-purchase-suggestion.component.css']
})
export class AdditionalPurchaseSuggestionComponent implements OnInit
{
  public isItemSelected: boolean = false;
  public purchaseSuggestios: IShoppingCartGeneralStoredSuggestionPlus[] = [];
  public purchaseSuggestionsAdditionalServicesList: IShoppingCartGeneralStoredSuggestionPlus[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected purchaseSuggestionDataService: PurchaseSuggestionDataService,
    private shoppingCartDataService: ShoppingCartDataService,
    private purchaseSuggestionManager: PurchaseSuggestionManager,
    protected checkoutManager: CheckoutManager,
    private settingsManager: SettingsManager,
    protected toast: ToastService,
    private router: Router,
    protected routeHelper: RouteHelper,
    private renderer: Renderer2,
    protected tokenManager: TokenManager,
    private destroyRef$: DestroyRef
  )
  {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: NavigationEnd) =>
      {
        if (event.url.includes('/recomendaciones'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });
  }

  public get showSuggestions(): boolean
  {
    return this.purchaseSuggestionsAdditionalServicesList.length > 0;
  }

  public get cartItems(): ShoppingCartItem[]
  {
    return this.checkoutManager.cartItems ? this.checkoutManager.cartItems.map(item => { return item.product; }) : [];
  }

  public get cartItemsCount(): number
  {
    return this.checkoutManager.cartItemsCount;
  }

  public get maxCartItems(): number
  {
    return this.settingsManager.itemsLimit;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.onSuggestionSelected();

    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);

    if (isPlatformBrowser(this.platformId))
    {
      this.renderer.addClass(document.body, 'blue-gray-background');
    }

    BroadcastService.Instance.broadcast(EAppEventName.OnCloseRemotePopup);
  }

  public registerEventListeners(): void
  {
    this.getPurchaseSuggestionsAdditionalService();
  }

  public onSuggestionSelected(): void
  {
    this.purchaseSuggestionManager.onSuggestionSelected()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.isItemSelected = this.tokenManager.getPurchaseSuggestionSelected() != null && this.tokenManager.getPurchaseSuggestionSelected().suggestions.length > 0;
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  public getPurchaseSuggestionsAdditionalService(): void
  {
    this.purchaseSuggestionDataService.getPurchaseSuggestionsFromAdditionalServices().subscribe({
      next: (response: IShoppingCartGeneralStoredSuggestionPlus[]) =>
      {
        this.purchaseSuggestionsAdditionalServicesList = this.proccessSuggestionsToShow(response);
        if (this.purchaseSuggestionsAdditionalServicesList.length === 0)
        {
          this.goToCart();
        }
        else
        {
          this.loadPurchaseSuggestionSelected();
        }
      }
    });
  }

  public loadPurchaseSuggestionSelected(): void
  {
    const purchaseSuggestionsSelected: IAdditionalPurchaseSuggestionSelected = this.tokenManager.getPurchaseSuggestionSelected();
    if (purchaseSuggestionsSelected !== null)
    {
      if (purchaseSuggestionsSelected.cartId === this.tokenManager.getCartCookie())
      {
        const areSuggestionsValid: boolean = purchaseSuggestionsSelected.suggestions.every(item =>

          this.purchaseSuggestionsAdditionalServicesList.some(item2 => item.suggestionId === item2.suggestionId));

        if (areSuggestionsValid)
        {
          this.isItemSelected = true;
        }
        else
        {
          this.tokenManager.removePurchaseSuggestionSelected();
        }
      }
      else
      {
        this.tokenManager.removePurchaseSuggestionSelected();
      }
    }
  }

  public async addToCart(): Promise<void>
  {
    if (this.cartItemsCount >= this.maxCartItems)
    {
      this.tokenManager.removePurchaseSuggestionSelected();
      this.goToCart();
    }
    else
    {
      let responsePost: any;
      const itemsAdded = this.tokenManager.getPurchaseSuggestionSelected() !== null ? this.tokenManager.getPurchaseSuggestionSelected().suggestions : [];
      const servicesAdded: IShoppingCartGeneralStoredSuggestionPlus[] = itemsAdded.filter(x => x.serviceToOffer.includes(EServiceCode.ASP) && x.serviceToOffer.includes(EServiceCode.APL));
      const domainsAdded: IShoppingCartGeneralStoredSuggestionPlus[] = itemsAdded.filter(x => x.serviceToOffer.includes(EServiceCode.DOT));

      const servsAddedToGtm: IProductAddGtm[] = [];

      if (servicesAdded.length > 0)
      {
        const additionalServiceToCart: AdditionalServiceToCart[] = servicesAdded.map(item =>
        {
          servsAddedToGtm.push(GtmTrackingService.buildProductSendToGtm(item.serviceToOffer, item.coverage?.rate));
          GtmTrackingService.addToCartSuggestionServiceEvent(item.serviceToOffer, item.coverage?.rate, EventLocationSite.RecommendationSuggestion);
          return { serviceData: item.serviceToOffer, quantity: 1, serviceCoverage: item.coverageMonths };
        });
        responsePost = await lastValueFrom(this.shoppingCartDataService.addSeveralService(additionalServiceToCart));
        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, responsePost.cartId);
      }

      if (domainsAdded.length > 0)
      {
        const domainList: IDomainCoverage[] = domainsAdded.map(item =>
        {
          const domainToAdded = item.serviceToOffer.slice(item.serviceToOffer.indexOf("=") + 1);
          GtmTrackingService.addToCartSuggestionServiceEvent(domainToAdded, item.coverage?.rate, EventLocationSite.RecommendationSuggestion);
          return { domain: domainToAdded, coverage: item.coverageMonths };
        });
        responsePost = await lastValueFrom(this.shoppingCartDataService.addDomains(domainList, EventLocationSite.RecommendationSuggestion));
        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, responsePost.cartId);
      }

      this.tokenManager.removePurchaseSuggestionSelected();
      this.goToCart();
    }
  }

  public goToCart(): void
  {
    this.changeLocationHref(this.routeHelper.shoppingCartWebURL);
  }

  public changeLocationHref(url: string): void
  {
    if (isPlatformBrowser(this.platformId) && url)
    {
      window.location.href = url;
    }
  }

  public goToHome(): void
  {
    this.router.navigate(['/']);
  }

  public proccessSuggestionsToShow(allPurchaseSuggsAddServs: IShoppingCartGeneralStoredSuggestionPlus[]): IShoppingCartGeneralStoredSuggestionPlus[]
  {

    allPurchaseSuggsAddServs = allPurchaseSuggsAddServs.map(item => this.suggestionsAdapter(item));
    return allPurchaseSuggsAddServs;
  }

  private suggestionsAdapter(purchaseSuggestion: IShoppingCartGeneralStoredSuggestionPlus): IShoppingCartGeneralStoredSuggestionPlus
  {
    return {
      ...purchaseSuggestion,
      coverage: purchaseSuggestion.coverage[0]
    };
  }
}
