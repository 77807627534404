import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBenefitTableCSS } from '@core-models/benefits.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { IColumns, IPlanTable } from '@core-models/microsoft-service-table';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-benefits-table',
  templateUrl: './benefits-table.component.html',
  styleUrls: ['./benefits-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitsTableComponent implements OnInit, OnChanges, ILanguageComponent
{
  @Input() public selectdBenefits: IPlanTable;
  @Input() public benefitTableCSS: IBenefitTableCSS;
  public showBenefits: boolean = false;

  constructor(private ref: ChangeDetectorRef,
    protected translateService: TranslateService,
    private destroyRef$: DestroyRef
  ){ }

    public ngOnInit(): void
    {
      this.registerEventListeners();
    }
  
    public registerEventListeners(): void
    {
      this.translateService.onTranslateChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: () =>
        {
          this.ref.detectChanges();
        }
      });
    }

  public getRangeOfLongestColumnLength(columns: IColumns[]): number[]
  {
    let maxLength = 0;
    const lengths = columns.map(obj => obj.data.length);
    maxLength = lengths.reduce((max, current) => Math.max(max, current), 0);

    return [...Array(maxLength).keys()];
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes['selectdBenefits'])
    {
      this.ref.detectChanges();
    }
  }

  public getColumnClasses(columnShow: boolean, index: number): { [key: string]: boolean }
  {
    return {
        'd-none': columnShow,
        [this.benefitTableCSS?.firstColumn]: index === 0
    };
  }

  public getOnlyFirstColumnClass(index: number): { [key: string]: boolean }
  {
    return {
      [this.benefitTableCSS?.firstColumn]: index === 0
    };
  }

  public getEvenRowClass(index: number): { [key: string]: boolean }
  {
    return {
      [this.benefitTableCSS?.evenRow]: index % 2 === 0
    };
  }
}
