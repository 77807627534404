<div class="card border-0 w-340">

  <!-- Row Recommended -->
  <div *ngIf="isRecommended" class="row container-recommend fs-m">
    <div class="col justify-content-center block-recommend">
      <span class="fas d-inline-block i-recommended-start align-middle"></span> {{ textRecommended }}
    </div>
  </div>

  <div [ngStyle]="{'background-color': cardBackground, 'border': '1px solid ' + priceBackground }"
    class="card-body gray-rounded-div p-0 position-relative h-100 flex-row flex-wrap justify-content-around">

    <!-- Title card -->
    <div class="plan-item-title space-top" [class.height-ssl]="isPlanLayout"
      [ngStyle]="{'background-color': titleBackground}">
      <div *ngIf="title" [innerHTML]="title | safe: 'html'"></div>
    </div>

    <!-- Price section -->
    <div [ngStyle]="{'background-color': priceBackground}" class="mt-auto pt-4 pb-3">
      <div *ngIf="currentPrice" [innerHTML]="currentPrice | safe: 'html'"></div>
      <div class="priceBottom fw-medium" *ngIf="lastPrice" [innerHTML]="lastPrice | safe: 'html'"></div>

      <!-- Quantity control -->
      <div *ngIf="isQuantityControl" class="d-flex justify-content-center align-items-center my-2 z-index-1">
        <div target="_blank"
          class="btn quantity-btn d-flex fw-bold  justify-content-center align-items-center text-uppercase d-flex">
          <div class="mx-2 fs-xxl clickable" (click)="onDecrementQuantityClicked()"> - </div>
          <span class="mx-2 text">{{ quantity === 0 ? ("cantidad" | translate) : quantity }}</span>
          <div class="mx-2 fs-xxl clickable" (click)="onIncrementQuantityClicked()"> + </div>
        </div>
      </div>
    </div>

    <!-- Add to cart button -->
    <div *ngIf="textButton" class="d-flex justify-content-center my-2 z-index-1 item-button-area action-button">
      <button class="btn btn-large my-3 mx-1" [ngClass]="action === 'cart' ? 'btn-primary' : 'btn-secondary'"
        id="{{ gtmKey }}" [attr.data-gtm-key]="gtmKey" (click)="onButtonClick()">
        {{ textButton }}
      </button>
    </div>

    <!-- Add combo to cart button -->
    <div *ngIf="packageId" class="d-flex justify-content-center position-relative my-2 item-button-area">
      <button class="btn-large btn my-3 mx-1 btn-info" id="{{ gtmKey }}" [attr.data-gtm-key]="gtmKey"
        (click)="onComboButtonClicked()">
        {{ textButtonCombo }}
      </button>
    </div>

    <!-- Descriptions and caraterictics -->
    <div class="px-3 pt-sm-0 position-relative z-index-1" *ngIf="characteristics">
      <div *ngIf="description" class="my-3 fsl font-weight-bold" [innerHTML]="description | safe: 'html'"></div>
      <div class="plan-features fw-medium" *ngIf="characteristics" [innerHTML]="characteristics | safe: 'html'"></div>
    </div>

    <div class="mb-35 border-0" *ngIf="cinta" [innerHTML]="cinta | safe: 'html'"></div>

    <div class="col-12 guarantee text-center" *ngIf="isRowGuarantee">
      <p class="py-2">
        <span class="fas d-inline-block i-ribbon-guarantee align-middle"></span>
        <a class="guarantee-link" href="{{ getGuaranteeAkkyURL() | blobstorage }}" target="_blank" rel="noreferrer">{{
          "Garantía de satisfacción AKKY" | translate }}</a>
      </p>
    </div>
  </div>
</div>