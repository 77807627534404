import { Injectable } from '@angular/core';
import { FBP_EVENTS, FBP_TACK_TYPES } from '@core-constants/fbp-trackting.const';
import { PixelAddToCartDataModel } from '@core-models/pixel.model';

declare let fbq: any;

@Injectable({
  providedIn: 'root'
})
export class PixelService
{
  constructor() { }

  public trackAddToCartEvent (data: PixelAddToCartDataModel = null): void
  {
    if (fbq)
    {
      fbq(FBP_TACK_TYPES.TRACK, FBP_EVENTS.ADD_TO_CART, data);
    }
  }
}
