import { IImageHelper } from "./graphql.models";

export interface ILayoutTranslation {
    titleExclusive: string;
    titleMonth: string;
    titleSeasonal: string;
}

export interface ILayout {
    translations: ILayoutTranslation[];
}

export interface IBenefitItemTranslation {
    img: IImageHelper;
    html: string;
}

export interface IBenefitItem {
    order: number;
    section: number;
    url: string;
    translations: IBenefitItemTranslation[];
    startDate: Date;
    endDate: Date;
}

export interface IBenefitsResponse {
    items: IBenefitItem[];
    layout: ILayout;
}

export interface IBenefitTableCSS
{
    firstColumn?: string;
    evenRow?: string;
    oddRow?: string;
}

export class IBenefitTableCSS
{
    public firstColumn?: string;
    public evenRow?: string;
    public iconClass?: string;

    constructor(firstColumn?: string, evenRow?: string, iconClass?: string)
    {
        this.firstColumn = firstColumn || '';
        this.evenRow = evenRow || '';
        this.iconClass = iconClass || '';
    }
}