import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SearchDomainCommonComponent } from '@core-components/search-domains/search-domains-common-component';
import { EAppEventName } from '@core-constants/app-events.const';
import { DomainConst, EDomainCodeError } from '@core-constants/domain.const';
import { FBP_CURRENCIES, FBP_PRODUCT_TYPES } from '@core-constants/fbp-trackting.const';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { PixelService } from '@core-data-services/pixel.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { IDomainCoverage, IResultDomain } from '@core-models/domain.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { PixelAddToCartDataModel } from '@core-models/pixel.model';
import { PackageModel } from '@core-models/service-package.model';
import { Environment } from '@environments';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-single-domain-search-result',
  templateUrl: './single-domain-search-result.component.html',
  styleUrls: ['../search-result-container.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleDomainSearchResultComponent extends SearchDomainCommonComponent implements OnInit, ILanguageComponent
{
  private _domain: IResultDomain;

  @Input() public set domain(value: IResultDomain)
  {
    this._domain = undefined;

    if (value)
    {
      this._domain = value;
      this._domain.packages = this.getDomainPromotions(value);
      this._domain.isFreeDomain = !value.price || Number(value.price) === 0;
    }
  }

  public get domain(): IResultDomain
  {
    return this._domain;
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    public shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    public promotionHelper: PromotionHelper,
    public localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
    public toast: ToastService,
    public pixelService: PixelService,
    protected interactiveToast: InteractiveToasterService,
    public router: Router,
    private destroyRef$: DestroyRef
  )
  {
    super(toast);
  }

  public get isFreeDomain(): boolean
  {
    return !this.domain.price || Number(this.domain.price) === 0;
  }

  public get domainsPackages(): PackageModel[]
  {
    const packages = this.localStorageService.getItem(LocalStorageConst.DomainPackages);

    return JSON.parse(packages);
  }

  public get hasDomainPackages(): boolean
  {
    return !Tools.isNullOrEmpty(this.domain.packages);
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public domainPackageHTML(domainPackage: PackageModel): string
  {
    return domainPackage ? domainPackage?.traducciones[0].titulo : "";
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.ref.detectChanges();
      }
    });
  }

  public getDomainPromotions(domain: IResultDomain): PackageModel[]
  {
    const list = this.domainsPackages.filter((x: any) => x.dominio === domain.domainType);

    return Tools.isNullOrEmpty(list) ? list : undefined;
  }

  public getDomainErrorMessage(): string
  {
    const domainErrorCode = this._domain?.error?.code;

    const message = DomainConst.CodeErrors.get(domainErrorCode);

    return this.translateService.getElement(message);
  }

  public isMaintenanceError(): boolean
  {
    const domainErrorCode = this.domain?.error?.code;

    return domainErrorCode === EDomainCodeError.DomainMaintenance;
  }

  public hasError(domain: IResultDomain): boolean
  {
    const domainErrorCode = domain?.error?.code;

    if (domainErrorCode === EDomainCodeError.TLDError ||
      domainErrorCode === EDomainCodeError.DomainMaintenance ||
      domainErrorCode === EDomainCodeError.InvalidRequest)
    {
      return true;
    }

    return false;
  }

  public onAddToCart(): void
  {
    if (this.domain)
    {
      this.disableAddDomainItemButton();
      const dto: IDomainCoverage[] = [];

      dto.push({ domain: this.domain.domainName, coverage: null });

      this.shoppingCartDataService.addDomains(dto, EventLocationSite.SearchDomain).subscribe({
        next: (response: any) =>
        {
          if (response.error != undefined && response.error != null)
          {
            this.notifyDomainErrors(response.cartListResult);
          }
          else
          {
            BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
            this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
            GtmTrackingService.addToCartDomainEvent(this.domain, false, EventLocationSite.SearchDomain);
            this.trackEvent(this.domain.domainType, this.domain.price);
          }
        },
        error: ({ error: { domains } }: any) =>
        {
          this.notifyDomainErrors(domains);
        }
      });
    }
  }

  private trackEvent(domainType: string, price: number): void
  {
    const productName = domainType;
    const data: PixelAddToCartDataModel = new PixelAddToCartDataModel(price, FBP_CURRENCIES.MXN, productName, FBP_PRODUCT_TYPES.ACQUISITION, [{ id: domainType, quantity: 1 }]);
    this.pixelService.trackAddToCartEvent(data);
  }

  public onAddPackageToCart(domainPackage: PackageModel): void
  {
    this.disableAddDomainItemButton();

    const services = this.promotionHelper.buildPromotionFromDomainPackage(this.domain, domainPackage);
    const coupon = domainPackage?.cupon ?? "";

    if (!Tools.isNullOrEmpty(services))
    {
      this.shoppingCartDataService.addPromotion(services, coupon).subscribe({
        next: (response: any) =>
        {
          if (response.error != undefined && response.error != null)
          {
            this.notifyDomainErrors(response.cartListResult);
          }
          else
          {
            this.notifySuccessDomainsAdded(response.cartListResult, response.cartId);
          }
        },
        error: ({ error: { domains } }: any) =>
        {
          this.notifyDomainErrors(domains);
        }
      });
    }

    GtmTrackingService.selectPromotionEventProcess('', EGtmSection.Domains, domainPackage.id.toString(), domainPackage.nombre, services, EventLocationSite.SearchDomain);
  }

  public goToPromotions(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const element = document.querySelector(`#most-searched`);

      if (element)
      {
        element.scrollIntoView();
      }
    }
  }

  public disableAddDomainItemButton(): void
  {
    this._domain.isEnabled = false;
  }

  public onRequirementsClicked(): void
  {
    this.router.navigate(['ayuda', 'temas-de-ayuda'], {
      relativeTo: null,
      queryParams: {
        tema: "Registro"
      }
    });
  }

  public navigateToPortalAkky(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = `${Environment.PortalAkkyURL}servicios/estatus-mantenimientos.jsf`;
    }
  }
}
