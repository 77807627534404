import { IPlanTable } from "@core-models/microsoft-service-table";

export namespace DudaConst
{
 export const BenefitsTable: IPlanTable = 
 {
  isColumnAsTable: false,
  columns: [
   {
    title: "", show: true,
    data: [
     {
      title: "",
      apps: [{ title: "Páginas", description: "Número de páginas que puede tener tu sitio." }]
     },
     {
      title: "",
      apps: [{ title: "Dominio personalizado", description: "Conecta tu propio dominio, potencializa tu marca y da una imagen profesional y confiable." }]
     },
     {
      title: "",
      apps: [{ title: "Sin publicidad", description: "Conserva un sitio libre de anuncios y ofrece una mejor experiencia a los visitantes." }]
     },
     {
      title: "",
      apps: [{ title: "Certificado SSL", description: "Protege la información confidencial de tu sitio como contraseñas, detalles de tarjetas de crédito y más." }]
     },
     {
      title: "",
      apps: [{ title: "Ancho de banda", description: "Tendrás el ancho de banda necesario para ofrecer una experiencia de contenido fluida y sin interrupciones a tus visitantes." }]
     },
     {
      title: "",
      apps: [{ title: "Editor sencillo IA", description: "Responde unas cuantas preguntas y en instantes tendrás tu sitio web listo para publicar." }]
     },
     {
      title: "",
      apps: [{ title: "Asistente IA", description: "Crea, edita y optimiza tu contenido, agrega SEO y genera metadatos de posicionamiento web con sugerencias de diseño, todo con solo unos clics." }]
     },
     {
      title: "",
      apps: [{ title: "Reservar citas", description: "Ofrece citas, ahorra tiempo y personaliza los detalles para cada reservación con tus clientes." }]
     },
     {
      title: "",
      apps: [{ title: "SEO incorporado", description: "Mejora el posicionamiento en los resultados de búsqueda, aumenta la relevancia del contenido, palabras clave, enlaces del sitio y mucho más." }]
     },
     {
      title: "",
      apps: [{ title: "Trabajo en equipo", description: "Invita hasta 20 colaboradores para editar tu sitio simultáneamente." }]
     },
     {
      title: "",
      apps: [{ title: "Estadísticas del sitio", description: "Obtén análisis sobre el tráfico de tu sitio, para saber con precisión todo lo qué ocurre en tu negocio y tomar decisiones." }]
     },
     {
      title: "",
      apps: [{ title: "Productos", description: "Crea un catálogo y permite que los clientes compren con facilidad agregando opciones para ordenar y filtrar." }]
     },
     {
      title: "",
      apps: [{ title: "Métodos de pago y envío flexible", description: "Acepta pagos seguros y administra todas las transacciones desde un solo lugar." }]
     },
     {
      title: "",
      apps: [{ title: "Sitios multilingües", description: "Establece con un solo clic la opción de cambiar el idioma de todo el contenido de tu sitio." }]
     },
     {
      title: "",
      apps: [{ title: "Diseño dinámico", description: "Copia y pega elementos, crea animaciones y establece reglas de personalización para tu sitio." }]
     }
    ]
   },
   {
    title: "Sitio Web Básico", show: true,
    data: [
     {
      title: "",
      apps: [{ description: "1" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ description: "Ilimitado" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     }
    ]
   },
   {
    title: "Sitio Web Avanzado", show: true,
    data: [
     {
      title: "",
      apps: [{ description: "10" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ description: "Ilimitado" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     }
    ]
   },
   {
    title: "Tienda en línea", show: true,
    data: [
     {
      title: "",
      apps: [{ description: "1,000" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ description: "Ilimitado" }]
     },
     {
      title: "",
      apps: [{ description: "—" }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     },
     {
      title: "",
      apps: [{ logo: EDudaIcon.Checked }]
     }
    ]
   }                
  ]
 };

 export const enum EDudaIcon
 {
  Checked = "/icons/checked.svg"
 }
}