import { ENavBarCategory } from "@core-constants/page-helper.const";
import { INavBarMenuItem } from "@core-models/menu-items.model";
import { Environment } from '@environments';

export const NavbarMenuItems: INavBarMenuItem[] =
  [
    {
      title: "Dominios",
      category: ENavBarCategory.Dominios,
      link: undefined,
      submenus: [
        {
          subtitle: "Obtén un dominio",
          options: [
            {
              name: "Registra un dominio",
              description: "Coloca tu nombre, el nombre de tu negocio, idea o proyecto en Internet.",
              link: '/servicios/dominios'
            },
            {
              name: "Transfiere un Dominio",
              description: "Traslada tu dominio a Akky, disfruta de diferentes beneficios.",
              link: '/servicios/transferencias'
            },
            {
              name: "Recupera un Dominio",
              description: "Considera la posibilidad de restaurar algún dominio que recientemente fue eliminado de tu administración",
              link: `${Environment.PortalAkkyURL}jsf/user_abc/account/update.jsf`
            },
            {
              name: "Cambia un Registrante",
              description: "Conoce la información del proceso de autenticación mediante el cual se realiza el cambio del Contacto Registrante (Titular) del dominio.",
              link: '/ayuda/temas-de-ayuda?tema=Configuración-Inicial'
            }
          ]
        }
      ]
    },
    {
      title: "Hosting",
      category: undefined,
      link: '/servicios/hosting',
      submenus: undefined
    },
    {
      title: "Sitios y tiendas",
      category: ENavBarCategory.SitiosTiendas,
      link: undefined,
      submenus: [
        {
          subtitle: "IMAGEN PROFESIONAL Y PERSONALIZADA",
          options: [
            {
              name: "Creador de Sitios Akky",
              description: "",
              link: '/servicios/creador-de-sitios-web'
            },
            {
              name: "Sitio Web con Wix",
              description: "",
              link: '/servicios/creador-sitios/creador-de-paginas/por-ti'
            },
            {
              name: "Tíenda en Línea con Wix",
              description: "",
              link: '/servicios/creador-sitios/tienda-en-linea/por-ti'
            },
            {
              name: "WordPress",
              description: "",
              link: '/servicios/wordpress/sitio-web'
            }
          ]
        }
      ]
    },
    {
      title: "Email y seguridad",
      category: ENavBarCategory.EmailSeguridad,
      link: undefined,
      submenus: [
        {
          subtitle: "EMAIL PERSONALIZADO Y ALMACENAMIENTO",
          options: [
            {
              name: "Email Profesional",
              description: "",
              link: '/servicios/email'
            },
            {
              name: "Certificados de Seguridad SSL",
              description: "",
              link: '/servicios/certificados-ssl'
            }
          ]
        }
      ]
    },
    {
      title: "Microsoft",
      category: undefined,
      link: '/servicios/microsoft/365',
      submenus: undefined
    },
    {
      title: "Promociones",
      category: undefined,
      link: '/promociones',
      submenus: undefined
    }
  ];
