export interface IPixelAddToCartDataModel 
{
    value: number;
    currency: string;
    content_name: string;
    content_type: string;
    contents: IPixelContentsDataModel[];
}

export interface IPixelContentsDataModel
{
    id: string;
    quantity: number;
    item_price?: number;
    location?: string; // Propiedad custom: ubicación específica de donde se agregó al carrito dicho producto
    type?: string; // Propiedad custom: tipo de adquisición (adquisición, renovación)
}

export class PixelAddToCartDataModel implements IPixelAddToCartDataModel
{
    public value: number;
    public currency: string;
    public content_name: string;
    public content_type: string;
    public contents: IPixelContentsDataModel[];

    constructor(value: number, currency: string, content_name: string, content_type: string, contents: IPixelContentsDataModel[])
    {
        this.value = value;
        this.currency = currency;
        this.content_name = content_name;
        this.content_type = content_type;
        this.contents = contents;
    }
}