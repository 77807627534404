<div *ngIf="selectdBenefits" class="container relocation px-2 px-sm-3 mt-1 px-md-5">
    <div class="text-center">
        <button class="btn btn-whatsapp" *ngIf="!selectdBenefits.isColumnAsTable"
            (click)="showBenefits = !showBenefits">
            {{ 'Ver características completas' | translate }}
        </button>
    </div>
    <div *ngIf="(showBenefits && selectdBenefits) || selectdBenefits.isColumnAsTable"
        [class.no-border]="selectdBenefits.isColumnAsTable" class="table-container">
        <table class="table-microsoft" [class.is-column-as-table]="selectdBenefits.isColumnAsTable">
            <thead>
                <tr>
                    <th *ngFor="let column of selectdBenefits.columns; let i = index"
                        [ngClass]="getColumnClasses(!column.show, i)" class="col-office center-title">
                        {{ column.title | translate }}
                    </th>
                </tr>
            </thead>
            <tbody class="tb-mcft-body">
                <tr *ngFor="let row of getRangeOfLongestColumnLength(selectdBenefits.columns); let i = index"
                    [ngClass]="getEvenRowClass(i)">
                    <td class="center-row-app" *ngFor="let column of selectdBenefits.columns; let i = index"
                        [ngClass]="getOnlyFirstColumnClass(i)">
                        <ng-container *ngIf="column.data[row]">
                            <div class="text-center w-100">
                                <span class="title-apps">{{ column.data[row].title |
                                    translate }}</span>
                            </div>
                            <div class="row-apps" [class.is-table-content]="selectdBenefits.isColumnAsTable">
                                <div *ngFor="let app of column.data[row].apps"
                                    [ngClass]="{ 'app': app.description === undefined }">
                                    <span *ngIf="app.title" class="font-weight-bold fs-l">{{ app.title | translate
                                        }}</span>
                                    <img *ngIf="app.logo" [class]="benefitTableCSS?.iconClass"
                                        [src]="app.logo | blobstorage" alt="{{ app.label }} logo">
                                    <span *ngIf="app.label" class="app-label">{{ app.label }}</span>
                                    <p class="text-justify" *ngIf="app.description">
                                        <br>
                                        <span [ngClass]="{'font-weight-bold fs-xs': app.title === undefined}"
                                            class="fs-l">{{
                                            app.description | translate }}</span>
                                    </p>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>