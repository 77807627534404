import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { EGtmEvent, GtmConst } from '@core-constants/gtm-const';
import { PageHelper } from '@core-constants/page-helper.const';
import { SessionParams, UTMParamConst } from '@core-constants/url-param.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { UTMOperationHelper } from '@core-helpers/utm-operation.helper';
import { TokenManager } from '@core-managers/token.manager';
import { UserAccessRequest, UserAccessResponse } from '@core-models/user-access.model';
import { Environment } from '@environments';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { TranslateService } from '@shared-services/translate.service';
import Validation from '@shared-utils/form-control-validation.util';
import { Tools } from '@shared-utils/tools.util';
import { CookieService } from 'ngx-cookie-service';
import { SettingsManager } from '@core-managers/settings.manager';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { HttpErrorCodeConst } from '@core-constants/http-error-code.const';

@Component({
  selector: 'app-user-access-form',
  templateUrl: './user-access-form.component.html',
  styleUrls: ['./user-access-form.component.css']
})
export class UserAccessFormComponent implements OnInit, OnChanges
{
  public isLoginLoadingVisible: boolean = false;
  public isSignUpLoadingVisible: boolean = false;
  public showSignUpView: boolean = false;
  public showPasswordLogin: boolean = false;
  public showAccessTokenLogin: boolean = false;
  public showPasswordSignUp: boolean = false;
  public termsChecked: boolean = false;
  public showSignUpErrorMessge: boolean = false;
  public showLoginErrorMessge: boolean = false;
  public notificationsChecked: boolean = true;
  private redirectToCart: boolean = false;

  // Login Form
  public loginForm: FormGroup;
  public loginSubmitted: boolean = false;
  public showCaptchaLogin: boolean = false;

  // Login ESU Form
  public loginEsuForm: FormGroup;
  public loginEsuSubmitted: boolean = false;

  // Singup Form
  public signupForm: FormGroup | undefined;
  public signupSubmitted: boolean = false;
  public isReCaptchaSolved: boolean = false;

  @ViewChild('recaptchaSignup') public recaptchaSignup: RecaptchaComponent;
  @ViewChild('recaptchaLogin') public recaptchaLogin: RecaptchaComponent;

  public signUpErrors: any[] = [];
  public loginErrors: any[] = [];

  private captchaResponse: string;
  public recaptchaV2SiteKey: string = Environment.RecaptchaV2SiteKey;

  @Input() public params: any = undefined;
  @Input() public enabled: boolean;
  @Input() public isModalDisplay: boolean = true;
  @Input() public isMainLogin: boolean = true;
  @Output() public onCloseForm = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private router: Router,
    private routeHelper: RouteHelper,
    private formBuilder: FormBuilder,
    private userAccessDataService: UserAccessDataService,
    private tokenManager: TokenManager,
    private cookieService: CookieService,
    private utmOperationHelper: UTMOperationHelper,
    private settingsManager: SettingsManager,
    protected checkoutManager: CheckoutManager,
    private destroyRef$: DestroyRef ) { }

  // convenience getter for easy access to form fields

  public get loginFormControls(): { [key: string]: AbstractControl<any>; }
  {
    return this.loginForm.controls;
  }

  public get signUpFormControls(): { [key: string]: AbstractControl<any>; }
  {
    return this.signupForm.controls;
  }

  public get loginEsuFormControls(): { [key: string]: AbstractControl<any>; }
  {
    return this.loginEsuForm.controls;
  }

  public get isMobile(): boolean
  {
    let isMob: boolean = false;

    if (isPlatformBrowser(this.platformId))
    {
      isMob = window.innerWidth <= PageHelper.MobileWidth;
    }

    return isMob;
  }

  public ngOnInit(): void
  {
    this.showSignUpView = false;

    this.loginForm = this.formBuilder.group({
      loginUsername: ['', Validators.required],
      loginPassword: ['', [Validators.required]]
    });

    this.loginEsuForm = this.formBuilder.group({
      loginUsernameEsu: ['', Validators.required],
      loginAccessToken: ['', [Validators.required]],
      loginUsernameExtranet: ['', [Validators.required]]
    });

    this.signupForm = this.formBuilder.group({
      signupUsername: ['', Validators.required],
      signupFirstname: ['', Validators.required],
      signupLastname1: ['', Validators.required],
      signupEmail: ['', Validators.required],
      signupConfirmEmail: ['', Validators.required],
      signupPassword: ['', [Validators.required, Validators.minLength(8)]]
    },
      {
        validators: [Validation.match('signupEmail', 'signupConfirmEmail')]
      });

    this.setDefaultError();
    this.validateUserIsLoggedIn();
    this.registerEventListeners();
  }

  public registerEventListeners(): void 
  {
    BroadcastService.Instance.on(EAppEventName.OnOpenSignUp)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.openSignup();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.redirectToCart = true;
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnCloseCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.redirectToCart = false;
      }
    });
  }

  public ngOnChanges(): void
  {
    this.showSignUpView = false;
  }

  // ********************************************************
  // #region Login
  // ********************************************************

  public onLoginSubmit(): void
  {
    this.loginSubmitted = true;

    if (this.loginForm.invalid)
    {
      return;
    }

    if (this.showCaptchaLogin && !this.isReCaptchaSolved)
    {
      return;
    }
    this.showLoadingLogin();

    const dto = this.getLoginDto();

    this.userAccessDataService.login(dto, this.captchaResponse).subscribe({
      next: (response: UserAccessResponse.IUser) =>
      {
        this.captchaResponse = "";
        this.showCaptchaLogin = false;
        GtmTrackingService.loginEvent(EGtmEvent.Login, response.userInfoModel.email);
        this.onOperationSuccess(response);
        this.publishLoginSuccess(response.cartId);
        this.hideLoadingLogin();
      },
      error: (response: HttpErrorResponse) =>
      {
        if (this.showCaptchaLogin)
        {
          this.recaptchaLogin.reset();
          this.isReCaptchaSolved = false;
        }
        if (response?.error?.code === HttpErrorCodeConst.InvalidCredentialsNeedCaptcha)
        {
          this.setLoginError(this.translateService.getElement(ErrorMessageConst.CaptchaNeededToContinue));
          this.showCaptchaLogin = true;
        }
        if (response?.error?.message)
        {
          this.setLoginError(response?.error?.message);
        }
        this.showLoginErrorMessge = true;
        this.hideLoadingLogin();
      }
    });
  }

  public getLoginDto(): UserAccessRequest.ILogin
  {
    const cartId = this.tokenManager.getCartCookie();

    const loginDto: UserAccessRequest.ILogin =
    {
      id: String(this.loginForm.value['loginUsername']).toLowerCase(),
      password: Tools.converteStringToBase64(this.loginForm.value['loginPassword']),
      language: this.translateService.languageCode,
      cartId: cartId ?? '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: ''
    };

    return this.utmOperationHelper.updateUTMData(loginDto);
  }

  public openLogin(): void
  {
    this.showSignUpView = false;
  }

  public publishLoginSuccess(cartId: string): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnLoginSuccess, cartId);
  }

  public publishSignUpSuccess(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnSignUpSuccess);
  }

  public setLoginError(message: string): void
  {
    this.loginErrors = [message];
  }

  public showLoadingLogin(): void
  {
    this.isLoginLoadingVisible = true;
  }

  public hideLoadingLogin(): void
  {
    this.isLoginLoadingVisible = false;
  }

  // #endregion

  // ********************************************************
  // #region Login Esu
  // ***
  public onLoginEsuSubmit(): void
  {
    this.loginEsuSubmitted = true;

    if (this.loginEsuForm.invalid)
    {
      return;
    }

    this.showLoadingLogin();

    const dto = this.getLoginEsuDto();

    this.userAccessDataService.loginEsu(dto).subscribe({
      next: (response: any) =>
      {
        GtmTrackingService.loginEvent(EGtmEvent.Login, response.userInfoModel.email);
        this.onOperationSuccess(response);
        this.publishLoginSuccess(response.cartId);
        this.hideLoadingLogin();
      },
      error: (response: HttpErrorResponse) =>
      {
        if (response?.error?.message)
        {
          this.setLoginError(response?.error?.message);
        }
        this.showLoginErrorMessge = true;
        this.hideLoadingLogin();
      }
    });
  }

  public getLoginEsuDto(): UserAccessRequest.ILoginEsu
  {
    const cartId = this.tokenManager.getCartCookie();

    const loginDto: UserAccessRequest.ILoginEsu =
    {
      id: String(this.loginEsuForm.value['loginUsernameEsu']).toLowerCase(),
      accessToken: Tools.converteStringToBase64(this.loginEsuForm.value['loginAccessToken']),
      user: String(this.loginEsuForm.value['loginUsernameExtranet']).toLowerCase(),
      language: this.translateService.languageCode,
      cartId: cartId ?? '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: ''
    };

    return this.utmOperationHelper.updateUTMData(loginDto);
  }


  public validateUserIsLoggedIn(): void
  {
    if (this.isMainLogin)
    {
      return;
    }

    const user = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();

    if (user && token)
    {
      this.goToHome();
    }
  }

  public goToHome(): void
  {
    this.router.navigate(['/']);
  }

  // #endregion 

  // ********************************************************
  // #region Signup
  // ********************************************************

  public onSignupSubmit(): void
  {
    this.signupSubmitted = true;

    if (this.isSignupValid())
    {
      this.showLoadingSignup();

      const dto: UserAccessRequest.ISingup = this.getSignupDto();

      this.userAccessDataService.singup(dto, this.captchaResponse).subscribe({
        next: (response: UserAccessResponse.IUser) =>
        {
          this.publishSignUpSuccess();
          this.onOperationSuccess(response);
          this.hideLoadingSignup();
          GtmTrackingService.signupEvent(EGtmEvent.Signup, GtmConst.TrackingMethodEmail, response.userInfoModel.email);
        },
        error: ({ error: errors }: any) =>
        {
          this.isReCaptchaSolved = false;
          this.signUpFormControls.signupPassword.reset();
          this.hideLoadingSignup();
          this.setSignupErrors(errors);
          this.recaptchaSignup.reset();
          this.showSignUpErrorMessge = true;
        }
      });
    }
  }

  public isSignupValid(): boolean
  {
    this.showSignUpErrorMessge = false;
    this.signUpErrors = undefined;
    this.signUpErrors = [];

    if (!this.termsChecked)
    {
      this.signUpErrors.push('Para continuar, debes aceptar los Términos y Condiciones y el Aviso de Privacidad.');
      this.signUpFormControls.signupPassword.reset();
      this.showSignUpErrorMessge = true;
    }

    if (!this.isReCaptchaSolved)
    {
      this.signUpErrors.push('El valor del captcha introducido es incorrecto.');
      this.signUpFormControls.signupPassword.reset();
      this.showSignUpErrorMessge = true;
    }

    if (this.signupForm.invalid || !this.isReCaptchaSolved || !this.termsChecked)
    {
      this.showSignUpErrorMessge = true;
    }

    return !this.showSignUpErrorMessge;

  }

  public getUserExtranetDto(): UserAccessRequest.IUserExtranet
  {
    const dto: UserAccessRequest.IUserExtranet =
    {
      id: this.signupForm.value['signupUsername'],
      firstName: this.signupForm.value['signupFirstname'],
      lastName1: this.signupForm.value['signupLastname1'],
      email: this.signupForm.value['signupEmail'],
      password: Tools.converteStringToBase64(this.signupForm.value['signupPassword']),
      hubSpotSubscribe: this.notificationsChecked
    };

    return dto;
  }

  public getSignupDto(): UserAccessRequest.ISingup
  {
    const userExtranetModel = this.getUserExtranetDto();
    const cartId = this.tokenManager.getCartCookie();

    const dto: UserAccessRequest.ISingup =
    {
      userExtranetModel,
      cartId: cartId ?? '',
      hubspotTrackingToken: this.tokenManager.getHubspotutk(),
      utmSource: '',
      utmMedium: '',
      utmCampaign: ''
    };

    return this.utmOperationHelper.updateUTMData(dto);
  }

  public openSignup(): void
  {
    this.showSignUpView = true;
  }

  public showLoadingSignup(): void
  {
    this.isSignUpLoadingVisible = true;
  }

  public hideLoadingSignup(): void
  {
    this.isSignUpLoadingVisible = false;
  }

  public setSignupErrors(errorsRaw: any[]): void
  {
    const errors = this.deserializeSignupErrors(errorsRaw);

    this.signUpErrors = Array.isArray(errors) ? errors.map(a => a.message) : [errors.message];
  }

  public deserializeSignupErrors(errors: any): any
  {
    const initialStateError = errors;

    try
    {
      return JSON.parse(errors.message);
    }
    catch (error)
    {
      return [initialStateError];
    }
  }
  // #endregion 

  public onOperationSuccess(userData: UserAccessResponse.IUser): void
  {
    this.cookieService.delete(UTMParamConst.Params, '/');
    this.onBackClicked(false);
    this.tokenManager.saveUserAuthenticationData(userData);
    this.goToExternalAkkyStartPanel();
    this.tokenManager.removeAditionalCartInformation();
  }

  public goToExternalAkkyStartPanel(): void
  {
    let postBackUrl = "";
    if (!this.redirectToCart) 
    {
      if (this.params != undefined && this.params[SessionParams.PostBackUrl] != undefined)
      {
        postBackUrl = this.params[SessionParams.PostBackUrl];
      }
    }
    else 
    {
      if (this.isPurchaseSuggestionPageValid())
      {
        postBackUrl = this.routeHelper.suggestionUrl;
      }
      else
      {
        postBackUrl = this.routeHelper.shoppingCartWebURL;
      }
    }

    this.userAccessDataService.redirectToAkkyOnLogin(postBackUrl);
  }

  public setDefaultError(): void
  {
    const hasError = this.params != undefined && this.params[SessionParams.Error];

    if (hasError && (hasError == "true" || hasError == true))
    {
      this.loginErrors = [];
      this.loginErrors.push(this.translateService.getElement('Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.'));

      this.showLoginErrorMessge = true;
    }
  }

  public onBackClicked(closePopup: boolean = true): void
  {
    this.showSignUpView = false;

    this.loginForm.reset();
    this.signupForm.reset();

    this.loginSubmitted = false;
    this.signupSubmitted = false;

    this.termsChecked = false;

    this.signupForm.updateValueAndValidity();
    this.loginForm.updateValueAndValidity();

    this.showLoginErrorMessge = false;
    this.showSignUpErrorMessge = false;

    this.loginErrors = [];
    this.signUpErrors = [];

    if (closePopup)
    {
      this.onCloseForm.emit(true);
    }
  }

  public onForgetPasswordClick(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}login/password/solicitar-regeneracion.jsf`);
  }

  public onRecoverAccountClick(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}login/password/recuperar-cuenta.jsf`);
  }

  public changeLocationHref(url: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = url;
    }
  }

  // ********************************************************************
  // #region Re-captcha Methods
  // ********************************************************************

  public onResolveCaptcha(captchaResponse: string): void
  {
    this.isReCaptchaSolved = true;
    this.captchaResponse = captchaResponse;
  }

  // #endregion

  public getErrorMessage(control: any, name: string, type: string = "text"): string
  {
    if (control)
    {
      if (control?.hasError('required'))
      {
        if (name)
        {
          return this.translateService.getElement('Por favor proporciona') + ': ' + this.translateService.getElement(name);
        }
        else
        {
          return this.translateService.getElement('Campo requerido');
        }

      }
      if (control?.hasError('email'))
      {
        return this.translateService.getElement('Por favor, ingresa un email válido');
      }
      if (control?.hasError('pattern'))
      {
        if (type.toLocaleLowerCase() == "email")
        {
          return this.translateService.getElement('Por favor, ingresa un email válido');
        }
        if (type.toLocaleLowerCase() == "password")
        {
          return this.translateService.getElement('De 8 a 16 caracteres, al menos (1) letra y (1) dígito');
        }

        return this.translateService.getElement('Por favor, ingresa un texto válido');
      }
      if (control?.hasError('invalid'))
      {
        return this.translateService.getElement('El dato ingresado es inválido');
      }
      if (control?.hasError('minlength'))
      {
        const length = control.errors.minlength?.requiredLength;
        const text = this.translateService.getElement("Longitud mínima de");
        const charWord = this.translateService.getElement("caracteres");

        return `${text} ${length} ${charWord}`;
      }
      if (control?.hasError('maxlength'))
      {
        return this.translateService.getElement('Ha superado la longitud de caracteres permitidos');
      }
      if (control?.hasError('matching'))
      {
        return this.translateService.getElement('El email no coincide');
      }
    }
  }

  public isPurchaseSuggestionPageValid(): boolean
  {
    return this.settingsManager.purchaseSuggestionPageEnabled === true && this.checkoutManager.cartItemsCount > 0;
  }
}
