<app-new-base-service [serviceData]="_serviceData" [serviceComparative]="dataComparative" [serviceID]="_serviceID">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serviceData">
      <div class="d-flex justify-content-around flex-wrap rg-50 align-items-stretch">
        <div *ngFor="let item of plans; let l = last">
          <app-service-plan [plan]="item" [location]="locationServicePage"></app-service-plan>
        </div>
      </div>
    </ng-container>
  </ng-template>
</app-new-base-service>