export enum FBP_TACK_TYPES {
    TRACK = 'track',
    TRACK_CUSTOM = 'trackCustom',
}

export enum FBP_EVENTS {
    ADD_TO_CART = 'AddToCart',
    PURCHASE = 'Purchase',
    COMPLETE_REGISTRATION = 'CompleteRegistration',
    INITIATE_CHECKOUT = 'InitiateCheckout',
  }
  
  export enum FBP_PRODUCT_TYPES {
    ACQUISITION = 'acquisition',
    RENEWAL = 'renewal',
  }
  
  export enum FBP_CURRENCIES {
    MXN = 'MXN'
  }