import { isPlatformBrowser } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID, ViewEncapsulation } from "@angular/core";
import { ClickActions } from "@core-constants/action-type.const";
import { EAppEventName } from "@core-constants/app-events.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { PromotionHelper } from "@core-helpers/promotion.helper";
import { ICupon } from "@core-models/service-package.model";
import { EFileRoute } from "@core-constants/file-routes.const";
import { EGtmSection } from "@core-constants/gtm-const";
import { InteractiveToasterConfigurations } from "@core-constants/interactive-toaster.const";
import { LanguageConst } from "@core-constants/language.const";
import { ServiceConst } from "@core-constants/product-service.const";
import { ServicesDisplayDataService } from "@core-data-services/services-display.data-service";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { IPlan } from "@core-models/plans.model";
import { ShoppingCarBaseContent } from "@core-models/shopping-cart-items.model";
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { BroadcastService } from "@shared-services/broadcast.service";
import { InteractiveToasterService } from "@shared-services/interactive-toast.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { PixelAddToCartDataModel } from "@core-models/pixel.model";
import { PixelService } from "@core-data-services/pixel.data-service";
import { HtmlExtractUtil } from "@shared-utils/htmlextrac.util";
import { Tools } from "@shared-utils/tools.util";
import { FBP_PRODUCT_TYPES } from "@core-constants/fbp-trackting.const";

@Component({
  selector: 'app-service-plan',
  templateUrl: './app-service-plan.component.html',
  styleUrls: ['./app-service-plan.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ServicePlanComponent
{
  public isPlanLayout: boolean = false;
  public isRecommended: boolean = false;
  public textRecommended: string = "";
  public cardBackground: string = '#F9F9F9';
  public priceBackground: string = '#ECEDEF';
  public titleBackground: string = '';
  public isQuantityControl: boolean = false;
  public action: string = "";
  public gtmKey: string = "";
  public packageId: number = null;
  public url: string = "";
  public isRowGuarantee: boolean = false;
  public cartServiceData: string = "";
  private name: string = "";

  public title: string = "";
  public currentPrice: string = "";
  public lastPrice: string = "";
  public textButton: string = "";
  public textButtonCombo: string = "";
  public characteristics: string = "";
  public description: string = "";
  public cinta: string = "";

  public quantity: number = 0;

  public comboServices: ICupon;

  public eventLocationSite: string = "";

  @Input() public set plan(value: IPlan)
  {
    const planInfo: IPlan = value;

    if (planInfo)
    {
      const planTranslation = planInfo?.traducciones[0];

      this.isPlanLayout = Object.prototype.hasOwnProperty.call(planInfo, 'plan_layout');
      this.isRecommended = planInfo.recomendado.toLowerCase() === "si" ? true : false;
      this.priceBackground = planInfo.color_area_precio;
      this.titleBackground = planInfo.color_area_precio;
      this.cardBackground = '#FFFFFF';
      this.isQuantityControl = planInfo.cantidad;
      this.action = planInfo.tipo_accion;
      this.gtmKey = planInfo.clave_gtm;
      this.packageId = planInfo.id_paquetes?.id;
      this.name = planInfo.nombre;
      this.url = planInfo.url_redireccionamiento;
      this.cartServiceData = planInfo.id_servicio_carrito?.valor;
      this.isRowGuarantee = planInfo.cintilla_garantia_akky;

      if (planTranslation)
      {
        this.textRecommended = planTranslation.texto_recomendado;
        this.title = planTranslation.titulo;
        this.currentPrice = planTranslation.precio_actual;
        this.lastPrice = planTranslation.antes_precio;
        this.textButton = planTranslation.texto_boton;
        this.textButtonCombo = planTranslation.texto_boton_combo;
        this.characteristics = planTranslation.caracteristicas;
        this.description = planTranslation.descripcion;
        this.cinta = planTranslation.cinta;
      }

      if (this.packageId)
      {
        this.getServicesPackage();
      }
    }
  }

  @Input() public set location(value: string)
  {
    this.eventLocationSite = value;
  } 

  constructor(@Inject(PLATFORM_ID) private platformId,
    private shoppingCartDataService: ShoppingCartDataService,
    protected toast: ToastService,
    protected interactiveToast: InteractiveToasterService,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    private pixelService: PixelService,
    private changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService) { }

  public identify(index: any, item: any): any
  {
    return item;
  }

  public onDecrementQuantityClicked(): void
  {
    this.quantity !== 0 && this.quantity--;
  }

  public onIncrementQuantityClicked(): void
  {
    this.quantity < ServiceConst.MaxQuantity && this.quantity++;
  }

  public getServicesPackage(): void
  {
    this.servicesDataService.getPackageById(this.packageId).subscribe({
      next: (data: ICupon) =>
      {
        if (data)
        {
          this.comboServices = data;
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  public onButtonClick(): void
  {
    if (this.action === ClickActions.Types.Redirect)
    {
      if (isPlatformBrowser(this.platformId))
      {
        window.location.href = this.url;
      }
    }
    else
    {
      this.addToCart();
    }
  }

  public addToCart(): void
  {
    if (!this.isQuantityControl)
    {
      this.quantity = 1;
    }

    this.shoppingCartDataService.addService(this.cartServiceData, this.eventLocationSite, this.quantity).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);

        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);

        GtmTrackingService.addToCartPresenceServiceEvent(this.cartServiceData, this.currentPrice, this.quantity, this.eventLocationSite );
        this.trackEvent(this.currentPrice, this.quantity, this.cartServiceData);
        this.quantity = 1;
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  private trackEvent(priceHtml: string, quantity: number, product: string): void
  {
    const price = HtmlExtractUtil.parsePrice(priceHtml);
    const aplId = Tools.convertServiceData(product)['APL_ID'];
    const productName = ServiceConst.AdditionalServicePlans.get(aplId);
    const data: PixelAddToCartDataModel = new PixelAddToCartDataModel(Number.parseFloat(price), 'MXN', productName, FBP_PRODUCT_TYPES.ACQUISITION, [{ id: product, quantity: quantity }]);
    this.pixelService.trackAddToCartEvent(data);
  }
  public onComboButtonClicked(): void
  {
    this.promotionHelper.addToCart(this.comboServices, EGtmSection.Plans, this.eventLocationSite);
  }

  public getGuaranteeAkkyURL(): string
  {
    return (this.translateService.languageCode == LanguageConst.ES) ? EFileRoute.AkkyGuaranteeEs : EFileRoute.AkkyGuaranteeEn;
  }

}
