<app-new-base-service [serviceData]="_serviceData" [serviceID]="_serviceID">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serviceData">
      <div class="d-flex justify-content-around flex-wrap rg-50 align-items-stretch">
        <app-service-plan *ngFor="let item of plans; let l = last" [plan]="item"
          [location]="locationServicePage"></app-service-plan>
      </div>

      <div *ngIf="selectdBenefits" class="space-blank-mcft">

      </div>

      <app-benefits-table [selectdBenefits]="selectdBenefits" [benefitTableCSS]="cssTable"></app-benefits-table>

    </ng-container>
  </ng-template>
</app-new-base-service>