import { isPlatformBrowser } from "@angular/common";
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from "@angular/core";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EventLocationSite } from "@core-constants/gtm-const";
import { EServiceCode } from "@core-constants/product-service.const";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { IDomainCoverage } from "@core-models/domain.model";
import { IPurchaseSuggestionItemAdded, IShoppingCartGeneralStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { ShoppingCarBaseContent } from "@core-models/shopping-cart-items.model";
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-purchase-suggestion-item',
  templateUrl: './purchase-suggestion-item.component.html',
  styleUrls: ["./purchase-suggestion-item.component.css"]
})
export class PurchaseSuggestionItemComponent
{
  private _item: IShoppingCartGeneralStoredSuggestionPlus;
  public isAdded: boolean = false;

  @Output() public onAddSuggestion = new EventEmitter<IPurchaseSuggestionItemAdded>();

  constructor(@Inject(PLATFORM_ID) private platformId,
    private shoppingCartDataService: ShoppingCartDataService,
    protected toast: ToastService,
    protected translateService: TranslateService,
    protected checkoutManager: CheckoutManager) { }

  @Input() public set item(value: IShoppingCartGeneralStoredSuggestionPlus)
  {
    this._item = value;
  }

  public get item(): IShoppingCartGeneralStoredSuggestionPlus
  {
    return this._item;
  }

  public get parentId(): string
  {
    return `${this.item.suggestionId}-purchase-suggestion`;
  }

  public get iconRoute(): string
  {
    return this._item.featureIconUrl;
  }

  public get label(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase()).textLabel;
  }

  public get title(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase())?.title;
  }

  public get toggleTitle(): string
  {
    return this.isAdded === true ? "Eliminar" : "Agregar a carrito";
  }

  public get subtitle(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase()).subtitle;
  }

  public get finalAmount(): number
  {
    return this._item.coverage.rate.finalAmount;
  }

  private get _isService(): boolean
  {
    return this._item.serviceToOffer.includes(EServiceCode.ASP) && this._item.serviceToOffer.includes(EServiceCode.APL);
  }

  private get _isDomain(): boolean
  {
    return this._item.serviceToOffer.includes(EServiceCode.DOT);
  }

  private getDomainDto(): IDomainCoverage[]
  {
    const domainList: IDomainCoverage[] = [];

    domainList.push({ domain: this._item.serviceToOffer.slice(this._item.serviceToOffer.indexOf("=") + 1), coverage: this._item.coverageMonths });

    return domainList;
  }

  private addDomainToCart(): void
  {
    const body: IDomainCoverage[] = this.getDomainDto();

    this.shoppingCartDataService.addDomains(body, EventLocationSite.GeneralSuggestion).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        GtmTrackingService.addToCartSuggestionServiceEvent(body[0].domain, this._item.coverage?.rate, EventLocationSite.GeneralSuggestion);

        const addedPurchaseSuggestion: IPurchaseSuggestionItemAdded =
        {
          cartId: response.cartId,
          suggestionId: this._item.suggestionId
        };

        this.onAddSuggestion.emit(addedPurchaseSuggestion);
      },
      error: () =>
      {
        this.isAdded = false;
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  private addServiceToCart(): void
  {
    this.shoppingCartDataService.addService(this._item.serviceToOffer, EventLocationSite.GeneralSuggestion, 1, this._item.coverageMonths).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        GtmTrackingService.addToCartSuggestionServiceEvent(this._item.serviceToOffer, this._item.coverage?.rate, EventLocationSite.GeneralSuggestion);

        const addedPurchaseSuggestion: IPurchaseSuggestionItemAdded =
        {
          cartId: response.cartId,
          suggestionId: this._item.suggestionId
        };

        this.onAddSuggestion.emit(addedPurchaseSuggestion);
      },
      error: () =>
      {
        this.isAdded = false;
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  public onCheckChanges($event: boolean): void
  {
    this.isAdded = $event;

    if (!this.isAdded || !isPlatformBrowser(this.platformId))
    {
      return;
    }

    if (this._isService)
    {
      this.addServiceToCart();
    }
    else if (this._isDomain)
    {
      this.addDomainToCart();
    }
  }
}
