import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SearchDomainCommonComponent } from '@core-components/search-domains/search-domains-common-component';
import { EAppEventName } from '@core-constants/app-events.const';
import { DomainConst, EDomainCodeError } from '@core-constants/domain.const';
import { EventLocationSite } from "@core-constants/gtm-const";
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { IDomainCoverage, IResultDomain } from '@core-models/domain.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { PackageModel } from '@core-models/service-package.model';
import { Environment } from '@environments';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-multiple-domain-search-result',
  templateUrl: './multiple-domain-search-result.component.html',
  styleUrls: ['../search-result-container.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleDomainSearchResultComponent extends SearchDomainCommonComponent implements OnInit, ILanguageComponent
{

  public addAllDomainsEnabled: boolean = true;
  private _domains: IResultDomain[] = [];

  @Input() public set domains(value: IResultDomain[])
  {
    this._domains = [];

    if (value)
    {
      this.enableAddAllDomainsButton();
      this._domains = value;

      this._domains.forEach((x: IResultDomain) =>
      {
        x.isFreeDomain = !x.price || Number(x.price) === 0;
      });
    }
  }

  public get domains(): IResultDomain[] { return this._domains; }

  constructor(@Inject(PLATFORM_ID) private platformId,
    public shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    protected localStorageService: LocalStorageService,
    public toast: ToastService,
    private ref: ChangeDetectorRef,
    protected interactiveToast: InteractiveToasterService,
    public router: Router,
    private destroyRef$: DestroyRef
  )
  {
    super(toast);
  }

  public get availableDomains(): IResultDomain[]
  {
    if (!Tools.isNullOrEmpty(this.domains))
    {
      return this.domains.filter(x => x.available === true && !x.isAvailableOffline);
    }

    return [];
  }

  public get availableOfflineDomains(): IResultDomain[]
  {
    if (!Tools.isNullOrEmpty(this.domains))
    {
      return this.domains.filter(x => x.available === true && x.isAvailableOffline);
    }

    return [];
  }

  public get hasAvailableDomains(): boolean
  {
    return this.availableDomains.length > 0;
  }

  public get hasOneAvailableDomain(): boolean
  {
    return this.availableDomains.length === 1;
  }

  public get hasAvailableOfflineDomains(): boolean
  {
    return this.availableOfflineDomains.length > 0;
  }

  public get unavailableDomains(): IResultDomain[]
  {
    if (!Tools.isNullOrEmpty(this.domains))
    {
      return this.domains.filter((x: any) => x.available === false);
    }
    return [];
  }

  public get domainsPackages(): PackageModel[]
  {
    const packages = this.localStorageService.getItem(LocalStorageConst.DomainPackages);

    return JSON.parse(packages);
  }

  public get hasUnvailableDomains(): boolean
  {
    return this.unavailableDomains.length > 0;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
     this.translateService.onTranslateChange()
     .pipe(takeUntilDestroyed(this.destroyRef$))
     .subscribe({
      next: () =>
      {
        this.ref.detectChanges();
      }
    });
  }

  public hasDomainPromotion(domain: IResultDomain): boolean
  {
    const exist = this.domainsPackages.find((x: any) => x.dominio === domain.domainType);

    return exist && exist !== undefined && exist !== null;
  }

  public getDomainErrorMessage(domain: IResultDomain): string
  {
    const domainErrorCode = domain?.error?.code;

    const message = DomainConst.CodeErrors.get(domainErrorCode);

    return this.translateService.getElement(message);
  }

  public isMaintenanceError(domain: IResultDomain): boolean
  {
    const domainErrorCode = domain?.error?.code;

    return domainErrorCode === EDomainCodeError.DomainMaintenance;
  }

  public hasError(domain: IResultDomain): boolean
  {
    const domainErrorCode = domain?.error?.code;

    if (domainErrorCode === EDomainCodeError.TLDError ||
      domainErrorCode === EDomainCodeError.DomainMaintenance ||
      domainErrorCode === EDomainCodeError.InvalidRequest)
    {
      return true;
    }

    return false;
  }

  public disableAddDomainItemButton(domain: IResultDomain): void
  {
    domain.isEnabled = false;
  }

  public onAddToCart(domain: IResultDomain): void
  {
    if (domain)
    {
      this.disableAddAllDomainsButton();
      const domainList: IDomainCoverage[] = [];

      domainList.push({ domain: domain.domainName, coverage: null });

      this.shoppingCartDataService.addDomains(domainList, EventLocationSite.SearchDomain).subscribe({
        next: (response: any) =>
        {
          if (response.error != undefined && response.error != null)
          {
            this.notifyDomainErrors(response.cartListResult);
          }
          else
          {
            BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
            this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
            GtmTrackingService.addToCartDomainEvent(this.availableDomains.find((obj) => { return obj.domainName === domain.domainName; }), false, EventLocationSite.SearchDomain);
          }
        },
        error: ({ error: { domains } }: any) =>
        {
          this.notifyDomainErrors(domains);
        }
      });
    }
  }

  public addAllDomainsToCart(): void
  {
    this.disableAddAllDomainsButton();

    const dto = this.generateAddToCartDto();

    this.availableDomains.forEach(domain => this.disableAddDomainItemButton(domain));

    this.shoppingCartDataService.addDomains(dto, EventLocationSite.SearchDomain).subscribe({
      next: (response: any) =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
        this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);

        GtmTrackingService.addToCartDomainEvent(this.availableDomains, true, EventLocationSite.SearchDomain);
      },
      error: ({ error: { error } }: any) =>
      {
        this.notifyDomainErrors(error.message);
      }
    });
  }

  public generateAddToCartDto(): IDomainCoverage[]
  {
    return this.availableDomains
      .filter(suggestion => suggestion.isEnabled)
      .map((suggestion: any) => <IDomainCoverage>
        {
          domain: suggestion.domainName, coverage: null
        });
  }

  public disableAddAllDomainsButton(): void
  {
    this.addAllDomainsEnabled = false;
  }

  public enableAddAllDomainsButton(): void
  {
    this.addAllDomainsEnabled = true;
  }

  public goToPromotions(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const element = document.querySelector(`#most-searched`);

      if (element)
      {
        element.scrollIntoView();
      }
    }
  }

  public onRequirementsClicked(): void
  {
    this.router.navigate(['ayuda', 'temas-de-ayuda'], {
      relativeTo: null,
      queryParams: {
        tema: "Registro"
      }
    });
  }

  public navigateToPortalAkky(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = `${Environment.PortalAkkyURL}servicios/estatus-mantenimientos.jsf`;
    }
  }
}
